import styled from 'styled-components';

import { useBreakpoints } from '@xing-com/crate-jobs-hooks';
import type { JobTeaserListItemProps } from '@xing-com/crate-jobs-xinglets';
import { MatchingReasonsHighlight } from '@xing-com/crate-jobs-xinglets';
import { lineClamp } from '@xing-com/crate-utils-line-clamp';
import { BodyCopy } from '@xing-com/typography';

type HighlightProps = Pick<
  JobTeaserListItemProps,
  'matchingReasons' | 'highlight'
>;
export const Highlight: React.FC<HighlightProps> = ({
  highlight,
  matchingReasons,
}) => {
  const { isMediaSmallOrTiny } = useBreakpoints();

  if (matchingReasons) {
    return <MatchingReasonsHighlight matchingReasons={matchingReasons} />;
  }

  return !isMediaSmallOrTiny && highlight ? (
    <HighlightContent size="medium" lineHeight="dense" noMargin>
      <span dangerouslySetInnerHTML={{ __html: highlight }} />
    </HighlightContent>
  ) : null;
};

const HighlightContent = styled(BodyCopy)`
  ${lineClamp(2)};
  word-break: break-word;
`;
